<template>
    <div class="register-route">
        <language-select-unauthorized
            class="language-selector"
        />
        <clouds v-if="welcomeTheme === 'CLOUDS'" />
        <astypalaia v-else-if="welcomeTheme === 'ASTYPALAIA'" />
        <clouds-with-gifts v-else />
        <div class="register-container">
            <div class="register-title">
                <b-img
                    :src="branding.primaryLogoUrl"
                    class="mx-2 pt-2"
                    alt=" "
                />
            </div>
            <div class="register-text">
                {{ $t('registerRoute.guideline') }}
            </div>
            <b-overlay
                :show="isLoading"
                variant="transparent"
                class="overlay"
                :opacity="0.1"
                rounded="lg"
                spinner-type="border"
            >
                <form
                    class="register-form"
                    :class="{'disabled-form': isLoading}"
                    @submit.prevent="getAccessCode"
                >
                    <tabs
                        class="tabs-container"
                        @tab-changed="onTabChanged"
                    >
                        <tab
                            :title="$t('registerRoute.email')"
                            tab-title="email"
                        >
                            <div class="form-group">
                                <input
                                    v-model.trim="email"
                                    type="email"
                                    class="form-control
                                    form-plain-input"
                                    :placeholder="$t('registerRoute.emailPlaceholder')"
                                >
                            </div>
                        </tab>
                        <tab
                            :title="$t('registerRoute.mobile')"
                            tab-title="mobile"
                        >
                            <div class="form-group">
                                <vue-phone-number-input
                                    v-model.trim="playerMobile.phoneNumber"
                                    :default-country-code="defaultCountryCode"
                                    :preferred-countries="getPreferredLanguages"
                                    class="register-phone-input"
                                    name="tel"
                                    no-example
                                    :dark="false"
                                    :dark-color="'#356c9a'"
                                    :translations="phoneNumberLocale"
                                    :border-radius="5"
                                    valid-color="#009FE3"
                                    error-color="red"
                                    no-flags
                                    :error="playerMobile.phoneNumber && !isPhoneNumberValid"
                                    @update="mobileChanged"
                                />
                            </div>
                        </tab>
                    </tabs>
                    <label
                        v-if="branding.termsPdfUrl || branding.privacyPdfUrl"
                        class="custom-checkbox"
                    >
                        <div class="checkbox-label">
                            <span class="approve-text"> {{ $t('registerRoute.checkbox[0]') }}</span>
                            <a
                                v-if="branding.termsPdfUrl"
                                :href="branding.termsPdfUrl || ''"
                                target="_blank"
                                class="terms-text"
                            > {{ $t('registerRoute.checkbox[1]') }}</a>
                            <span
                                v-if="branding.privacyPolicyPdfUrl"
                                class="approve-text"
                            > {{ $t('registerRoute.checkbox[2]') }}</span>
                            <a
                                v-if="branding.privacyPolicyPdfUrl"
                                :href="branding.privacyPolicyPdfUrl || ''"
                                target="_blank"
                                class="terms-text"
                            > {{ $t('registerRoute.checkbox[3]') }}</a>
                        </div>
                        <input
                            id="checkbox-1"
                            v-model="acceptTerms"
                            name="checkbox-1"
                            type="checkbox"
                        >
                        <span class="checkmark" />
                    </label>
                    <div class="errors-container">
                        <span v-if="registrationFailedError">{{ registrationFailedError }}</span>
                    </div>
                    <button
                        type="submit"
                        class="custom-btn"
                        :disabled="isGetCodeDisabled"
                        :class="{'disabled': isGetCodeDisabled}"
                    >
                        {{ $t('registerRoute.getAccessCode') }}
                    </button>
                    <div class="pt-3"><span>{{ $t('registerRoute.alreadyRegister[0]') }}</span><router-link class="go-to-login" to="/login">{{ $t('registerRoute.alreadyRegister[1]') }}</router-link></div>
                </form>
                <hr>
                <div
                    class="quick-password-text"
                    :class="{'disabled-form': isAccessCodeFormDisabled}"
                >
                    {{ $t('registerRoute.accessCodeGuideline') }}
                </div>
                <form
                    class="register-form"
                    :class="{'disabled-form': isAccessCodeFormDisabled}"
                    @submit.prevent="submitAccessCode"
                >
                    <input
                        v-model.trim="accessCode"
                        type="text"
                        :disabled="isAccessCodeFormDisabled"
                        class="form-control form-plain-input"
                    >
                    <div class="errors-container">
                        <span v-if="loginFailedError">{{ loginFailedError }}</span>
                    </div>
                    <button
                        type="submit"
                        class="custom-btn"
                        :disabled="isAccessCodeFormDisabled"
                        :class="{'disabled': isAccessCodeFormDisabled}"
                    >

                        <span v-if="!isSubmitting">{{ $t('registerRoute.submit') }}</span>
                        <b-spinner v-else small />
                    </button>
                </form>
            </b-overlay>
        </div>
        <a
            v-if="branding.impressumPdfUrl"
            :href="branding.impressumPdfUrl || ''"
            class="impressum"
            target="_blank"
        >{{ $t('registerRoute.impressum') }}
        </a>
    </div>
</template>

<script>
    import Tab from '@/components/tabs/Tab'
    import Tabs from '@/components/tabs/Tabs'
    import playerService from '@/services/player-service'
    import VuePhoneNumberInput from 'vue-phone-number-input'
    import 'vue-phone-number-input/dist/vue-phone-number-input.css'
    import CloudsWithGifts from '@/components/welcome-backgrounds/CloudsWithGifts'
    import Clouds from '@/components/welcome-backgrounds/Clouds'
    import Astypalaia from '@/components/welcome-backgrounds/Astypalaia'
    import LanguageSelectUnauthorized from '@/components/LanguageSelectUnauthorized'
    import statsHelper from '@/mixins/statsHelper'
    export default {
        components: {
            VuePhoneNumberInput,
            Tab,
            Tabs,
            LanguageSelectUnauthorized,
            CloudsWithGifts,
            Clouds,
            Astypalaia
        },
        mixins: [ statsHelper ],
        data() {
            return {
                email: null,
                mobile: null,
                playerMobile: {
                    countryCode: 'DE',
                    phoneNumber: null
                },
                isPhoneNumberValid: true,
                isLoading: false,
                phoneNumberLocale: {
                    countrySelectorLabel: '',
                    phoneNumberLabel: this.$t('registerRoute.mobilePlaceholder')
                },
                isAccessCodeFormDisabled: true,
                acceptTerms: false,
                registrationFailedError: null,
                loginFailedError: null,
                accessCodeSent: false,
                accessCode: null,
                isSubmitting: false,
                errorMap: {
                    PLAYER_EXISTS: this.$t('registerRoute.playerExists'),
                    NO_CONTACT_TO_ACCEPT: this.$t('registerRoute.noContactToAccept'),
                    PLAYER_NOT_EXISTS: this.$t('registerRoute.playerNotExists'),
                    EMPTY_EMAIL: this.$t('registerRoute.emptyEmail'),
                    INVALID_EMAIL: this.$t('registerRoute.invalidEmail'),
                    INVALID_MOBILE: this.$t('registerRoute.invalidMobile'),
                    INVALID_ACCESS_CODE: this.$t('registerRoute.accessCode'),
                    TRY_AGAIN_LATER: this.$t('registerRoute.tryAgainLater')
                }
            }
        },
        computed: {
            branding() {
                return this.$store.getters.getBranding
            },
            isGetCodeDisabled() {
                return (!this.email && !this.mobile) || (!this.acceptTerms && !!this.branding.termsPdfUrl) || this.accessCodeSent
            },
            freeTaskInfo() {
                return this.$store.getters.getFreeTaskInfo
            },
            scanCode() {
                return this.$store.getters.getScanCode
            },
            welcomeTheme() {
                return this.$store.getters.getWelcomeTheme
            },
            accessCodeLength() {
                return this.$store.getters.getAccessCodeLength
            },
            getPreferredLanguages() {
                return this.$store.getters.getPreferredLanguages || []
            },
            defaultCountryCode() {
                return this.getPreferredLanguages?.length > 0 ? this.getPreferredLanguages[0] : ''
            }
        },
        watch: {
            accessCode: {
                handler(newValue) {
                    if (newValue?.length === this.accessCodeLength) {
                        this.submitAccessCode()
                    }
                },
                immediate: true
            }
        },
        methods: {
            isEmailValid(email) {
                let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return regex.test(email)
            },
            async getAccessCode() {
                this.isLoading = true
                let data = null

                if (this.email && !this.isEmailValid(this.email)) {
                    this.registrationFailedError = this.errorMap['INVALID_EMAIL']
                    setTimeout(() => {
                        this.registrationFailedError = null
                    }, 5000)
                    this.isLoading = false
                    return
                }

                if (!this.acceptTerms && this.branding.termsPdfUrl) {
                    this.passwordPolicyFail = false
                }

                if (this.email) {
                    const recaptchaResponse = await this.recaptcha('EMAIL_REGISTER')
                    data = this.email
                    playerService.emailRegister(data, recaptchaResponse)
                        .then(() => {
                            this.accessCodeSent = true
                            this.isAccessCodeFormDisabled = false
                            if (this.scanCode) {
                                this.scanQrSpot(this.scanCode, 'QR_SCAN', 'REGISTER')
                            }
                        }, (error) => {
                            this.handleRegisterError(error)
                        })
                        .finally(() => { this.isLoading = false })
                } else if (this.mobile) {
                    const recaptchaResponse = await this.recaptcha('MOBILE_REGISTER')
                    data = this.mobile
                    playerService.mobileRegister(data, recaptchaResponse)
                        .then(() => {
                            this.accessCodeSent = true
                            this.isAccessCodeFormDisabled = false
                            if (this.scanCode) {
                                this.scanQrSpot(this.scanCode, 'QR_SCAN', 'REGISTER')
                            }
                        }, (error) => {
                            this.handleRegisterError(error)
                        })
                        .finally(() => { this.isLoading = false })
                }
            },
            async submitAccessCode() {
                this.isSubmitting = true
                const reCaptchaResponse = await this.recaptcha('CODE_LOGIN')
                this.$store.dispatch('loginWithAccessCode', { accessCode: this.accessCode, freeTaskInfo: this.freeTaskInfo, reCaptchaResponse })
                    .catch((error) => {
                        this.handleLoginError(error)
                    }).finally(() => {
                        this.isSubmitting = false
                    })
            },
            onTabChanged(tab) {
                if (tab.$attrs['tab-title'] === 'mobile') {
                    this.email = null
                } else if (tab.$attrs['tab-title'] === 'email') {
                    this.mobile = null
                    this.playerMobile.phoneNumber = null
                }
            },
            mobileChanged(phone) {
                this.playerMobile.countryCode = phone.countryCallingCode
                if (!phone.hasOwnProperty('phoneNumber')) {
                    this.mobile = null
                }
                if (phone.hasOwnProperty('isValid')) {
                    this.isPhoneNumberValid = phone.isValid
                }
                if (phone?.formattedNumber) {
                    this.mobile = phone.formattedNumber.substring(1)
                }
            },
            handleRegisterError(error) {
                if (error.response.status === 503) {
                    this.registrationFailedError = this.errorMap['TRY_AGAIN_LATER']
                } else {
                    this.registrationFailedError = this.errorMap[error.response.data.code]
                }
                setTimeout(() => {
                    this.registrationFailedError = null
                }, 5000)
            },
            handleLoginError(error) {
                this.loginFailedError = this.errorMap[error.response.data.code]
                setTimeout(() => {
                    this.loginFailedError = null
                }, 5000)
            }
        }
    }
</script>

<style>
.register-phone-input.vue-phone-number-input {
    gap: 1rem;
    min-height: 48px;
}

.register-phone-input .select-country-container {
    min-height: 48px;
    width: 90px !important;
    min-width: 90px !important;
    max-width: 90px !important;
}

.register-phone-input .input-country-selector {
    min-height: 48px;
}

.register-phone-input .input-tel__input::placeholder {
    color: #009fe3 !important;
}

.register-phone-input .country-selector__input,
.register-phone-input .input-tel__input {
    height: 48px;
    padding: 0  1rem !important;
    font-size: 1.1rem;
    font-family: var(--font-family) !important;
    color: #009fe3 !important;
}

.register-phone-input .country-selector__label,
.register-phone-input .input-tel__label {
    display: none !important;
}

@media screen and (min-width: 768px) {
    .register-phone-input .country-selector__input,
    .register-phone-input .input-tel__input {
        font-size: 1.2rem !important;
    }
}

</style>

<style lang="scss" scoped>
.register-route {
    width: 100%;
    min-height: 100vh;
    display: grid;
    place-items: center;
    position: relative;
}

.language-selector {
    position: absolute;
    top: 1%;
    left: 2%;
    z-index: 1;
}

.register-container {
    color: var(--white);
    max-width: 555px;
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0 1rem;
    z-index: 1;
}

.register-title img {
    height: 95px;
}

.register-text {
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 1.5;
}

.register-form {
    max-width: 420px;
    margin: 1rem auto 0;
    color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 0 0 2rem;
}

.form-control::placeholder {
    color: #009fe3;
    opacity: 0.6;
}

.tabs-container {
    margin-top: 1rem;
}

.approve-text {
    color: var(--white);
}

.terms-text {
    font-weight: 700;
    color: var(--white);
    text-decoration: none;
}

.form-plain-input {
    height: 48px;
    width: 90%;
    margin: 0 auto;
    font-size: 1.1rem !important;
    color: #009fe3;
}

.custom-checkbox {
    margin: 1rem auto 0;
    display: block;
    position: relative;
    cursor: pointer;
    text-align: left;
    padding-left: 2rem;
    width: 90%;
}

input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 9%;
    left: 0;
    height: 1.1rem;
    width: 1.1rem;
    background-color: var(--white);
    border: 3px solid var(--white);
    transition: all 0.15s ease-out;
}

.custom-checkbox input:checked ~ .checkmark {
    background-color: #193674;
}

.errors-container {
    margin: 0.5rem 0;
    height: 1rem;
    font-weight: 700;
    color: var(--form-error-color);
}

.custom-btn {
    max-width: 420px;
    width: 90%;
    margin: 0 auto;
    height: 60px;
    border-radius: 20px;
    display: grid;
    place-items: center;
    font-size: 1.1rem;
    letter-spacing: 0.3em;
    font-weight: 500;
    background: #193674;
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    color: var(--white);
    text-decoration: none;
    border: none;
}

.custom-btn:hover:enabled {
    background: #f1b12b;
}

.custom-btn:active:enabled {
    transform: scale(0.9);
}

.custom-btn.disabled {
    background: var(--disabled-color);
    cursor: not-allowed;
}

.email-sent {
    font-weight: 700;
    font-size: 14px;
    color: var(--black);
}

.quick-password-title {
    color: var(--white);
    font-size: 2rem;
    font-weight: 500;
}

.quick-password-text {
    color: var(--white);
    margin: 3rem 0 0;
}

.disabled-form {
    opacity: 0.5;
}

.overlay {
    color: #f1b12b;
}

hr {
    width: 300px;
    background: var(--white);
}

.impressum {
    z-index: 1;
    padding: 0.25rem;
    color: white;
}

@media screen and (min-width: 768px) {
    .language-selector {
        left: 1%;
    }

    .register-text {
        line-height: 1.5;
        font-size: 1rem;
        margin-top: 1rem;
    }

    .register-container {
        color: var(--white);
        max-width: 420px;
        width: 100%;
        margin: 0 auto;
        padding: 2rem 0 1rem;
    }

    .register-form {
        margin-top: 0;
        gap: 0;
    }

    .errors-container {
        height: 2rem;
    }

    .form-plain-input {
        width: 100%;
        font-size: 1.2rem !important;
    }

    .custom-btn {
        font-size: 1.25rem;
        height: 65px;
        width: 100%;
    }

    .custom-checkbox {
        width: 100%;
    }

    .quick-password-title {
        margin-top: 1.5rem;
    }

    .quick-password-text {
        margin: 3rem 0 1rem;
    }

    .go-to-login {
        color: white;
        text-decoration: underline;
    }

    .impressum {
        font-size: 1.25rem;
    }
}
</style>
